import React, { useEffect } from 'react'
import { Customer } from './MultiCarousel'
import Button from './Button';
import { useNavigate } from 'react-router-dom';
import AOS from "aos";
import "aos/dist/aos.css";

const customer = [
    {
        img : ` `,
        name :'Kyle Roberts DVM',
        post :'Customer Web Consultant',
        text :"Website design did exactly what you said it does. Just what I was looking for. Nice work on your website design.",
        star : 4
    },
    {
        img : ` `,
        name :'Sophia Anderson',
        post :'Internal Implementation Officer',
        text :"I will let my mum know about this, she could really make use of software! Very easy to use. Since I invested in software I made over 100,000 dollars profits. I just can't get enough of software. I want to get a T-Shirt with software on it so I can show it off to everyone.",
        star : 5
    },
    {
        img : ` `,
        name :'Stephen Brekke',
        post :'Legacy Integration Producer',
        text :"If you want real marketing that works and effective implementation - mobile app's got you covered.",
        star : 4
    }
]

const StarIcon = ({ count }) => {
    // Replace this with your star icon component, e.g., <span>*</span> or an SVG icon
    return (
      <div>
        {Array.from({ length: count }, (_, index) => (
          <span key={index}><i class="bi bi-star-fill text-primary"></i></span>
        ))}
      </div>
    );
  };  
const customerStotry = () =>{
    const arr = []
     for(let i = 0 ; i < customer.length ; i++){
        arr.push(
            <div style={{position:'relative'}}> 
                <div class='bg-white rounded-4'>
                    <div class='col-11 m-auto'> 
                        <div class='d-flex justify-content-end gap-3 pt-3'>
                            <StarIcon count={customer[i].star}/> 
                        </div>
                        <div class='my-3'>
                            <p class='tJustify'>{customer[i].text}</p>
                        </div>
                        <div class='d-flex justify-content-between  align-items-center pb-3  gap-1'>
                            <div class='text-start'>
                                <p class='fw-bold'>{customer[i].name}</p>
                                <p class='text-secondary' style={{fontSize:'14px'}}>{customer[i].post}</p> 
                            </div>
                            <div class='d-flex gap-2 align-items-center'>
                                <div class='p-1 bg-primary d-flex align-items-center justify-content-center ' style={{width:'25px',height:'25px',borderRadius:'50%'}}>
                                    <p  style={{fontSize:'14px'}}><i class="bi bi-hand-thumbs-up-fill text-white  "></i></p>
                                </div>
                                <p class='fw-bold'>Testimonial</p>
                            </div>
                        </div>
                     
                    </div> 
                </div>
                {/* <div style={{position:'absolute',width:'70px',height:'70px',borderRadius:'50%',top:'-35px',left:'35px'}}>
                    <img src={customer[i].img} style={{width:'100%',height:'100%',objectFit:'contain'}} alt="" />
                </div> */}
            </div>
        )
     }
     return arr;
}

const AutoRefractometer = () => {

    useEffect(() => {
        AOS.init({ duration: 1500 });
      });
    const navigate = useNavigate()
  return (
    <div>
        <div class='px-3'>
            <div class='text-start'> 
            <p class='titleText fw-bold' style={{color:'#27448F'}} data-aos="fade-up"> <span>Auto</span></p>

                <p class='titleText fw-bold' data-aos="fade-up"> <span>Refractometer</span> </p>
            </div>

            <div class='row mt-5'> 
                <div class='col-lg-4 col-md-6 col-12 p-3'>
                    <div class=' m-auto rounded-5 py-4 h-100 row text-start productBox' style={{background:'#2A4CA3',cursor:'pointer'}} onClick={()=>navigate('/gn-9900')} >
                        <div class='col-11 m-auto'>
                            <p class='fs-2 fw-bold text-white'>Grand Nippon GN-9900 AUTO REFRACTOMETER</p>
                            <a href="" class='text-white ' onClick={()=>navigate('/gn-9900')}>Read More</a>
                        </div>
                        <div class='col-12  imgIffect d-flex align-content-center justify-content-center'>
                            <img src={require('../image/gn9900.png')} style={{width:' ',height:'300px'}} alt="" data-aos="zoom-out" />
                        </div>
                    </div>
                </div>
                <div class='col-lg-4 col-md-6 col-12 p-3'>
                    <div class=' m-auto rounded-5 py-4 h-100 row text-start productBox' style={{background:'#5383FF',cursor:'pointer'}} onClick={()=>navigate('/gn-200')} >
                        <div class='col-11 m-auto'>
                            <p class='fs-2 fw-bold text-white'>Grand Nippon GN-200 Auto Refractometer</p>
                            <a href="" class='text-white ' onClick={()=>navigate('/gn-200')}>Read More</a>
                        </div>
                        <div class='col-12  imgIffect d-flex align-content-center justify-content-center'>
                            <img src={require('../image/gn200.png')} style={{width:' ',height:'300px'}} alt="" data-aos="zoom-out" />
                        </div>
                    </div>
                </div>  

                <div class='col-lg-4 col-md-6 col-12 p-3'>
                    <div class=' m-auto rounded-5 py-4 h-100 row text-start productBox' style={{background:'#0DCAF0',cursor:'pointer'}}  onClick={()=>navigate('/ark-7600')} >
                        <div class='col-11 m-auto'>
                            <p class='fs-2 fw-bold text-black'>AR/K-7600 Auto Refractokeratometer - Precise, Reliable, and Efficient</p>
                            <a href="" class='text-black ' onClick={()=>navigate('/ark-7600')}>Read More</a>
                        </div>
                        <div class='col-12  imgIffect d-flex align-content-center justify-content-center'>
                            <img src={require('../image/ark7600.png')} style={{width:' ',height:'300px'}} alt="" data-aos="zoom-out" />
                        </div>
                    </div>
                </div> 

            </div>
             
        </div>
        {/* <div  class='mt-5' style={{background:'#EDF0FA'}}>
            <div class='col-11 m-auto py-5'> 
                <p class='display-4 fw-bold' data-aos="fade-up">CUTOMER</p>
                <p class='display-4 fw-bold' style={{color:'#27448F'}} data-aos="fade-up">SUCCESS STORIES</p>
                <p class='col-md-5 col-11 m-auto fs-5' data-aos="fade-up">Hear from our satisfied B2B clients and learn how our optical machines have transformed their businesses.</p>
            </div> 
        </div>
        <div style={{background:'linear-gradient(#EDF0FA 40%,#2A4BA3 0)'}}>
            <div class='col-11 m-auto'>

                 <Customer data={customerStotry()}/> 
            </div>
        </div> */}
        <div style={{background:'#2A4BA3',marginTop:'100px'}}> 
        <br /><br />
            <div class='row col-11 m-auto py-4 gap-lg-0 gap-4 text-lg-center text-start  ' > 
                <div class='col-lg-8 col-12 m-auto text-start'>
                    <p class='display-4 text-white fw-bold ' data-aos="fade-up">WANT TO KNOW MORE ABOUT OUR PRODUCTS PLEASE CONNECT.</p>
                </div>
                <div class='col-lg-4 col-12 m-auto '>
                    <div class=' text-primary' style={{width:'fit-content',borderRadius:'3px',background:'#ffffff' }} onClick={()=>navigate('/request-quote')} > 
                        <Button class='p-2' name={'Get a Quote'}/>
                    </div>
                </div> 
            </div>
            <br /><br /><br />
        </div>
    </div>
  )
}

export default AutoRefractometer