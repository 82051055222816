import React, { useEffect } from 'react'
import AOS from "aos";
import "aos/dist/aos.css";
import { Certifications, WhyChoose } from './MultiCarousel';
import Button from './Button';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const whyChoose = [
    {
        title :'Unparalleled Legacy',
        text :'Seven Decades of Optical Excellence Discover our rich history and commitment to optical innovation.'
    },
    {
        title :'Trusted by Professionals',
        text :'Renowned Eye Care Partners Join our loyal clientele of Eye Care Professionals and corporate leaders.'
    },
    {
        title :'Comprehensive Solutions',
        text :'Your One-Stop Optical Machinery Source Explore a diverse range of cutting-edge equipment for all your needs.'
    },
    {
        title :'Generations of Expertise',
        text :'Your Ultimate Eyecare Destination Experience technical excellence with three generations of optical expertise.'
    },
    {
        title :'Unwavering Quality',
        text :'Excellence in Optical Solutions, Always at Diamondlab Count on us for swift deliveries, exceptional after-sale services, and more.'
    }
]

const whyCart = () =>{
    let arr = []
    for(let i = 0 ; i  < whyChoose.length ; i++){
        arr.push(
            <div class='rounded-4  bg-dark text-white text-start' style={{position:'relative',width:'280px',height:'200px'}} data-aos="fade-up">
                <div class='p-2'>
                    <div class='col-11 m-auto my-3'> 
                        <p class='fs-5 fw-bold'>{whyChoose[i].title}</p>
                        <p class='mt-2'>{whyChoose[i].text}</p>
                    </div>
                </div>
                <div style={{width:'30px',height:'30px',position:'absolute',top:'-15px',left:'30px'}}>
                    <img src={require('../image/cartIcon.png')} style={{width:'100%',height:'100%'}} alt="" />
                </div>
           </div>
        )
    }

    return arr;
}

 

 

const About = () => {
    useEffect(() => {
        AOS.init({ duration: 1500 });
      });

      const nevigate = useNavigate()
  return (
    <div>
            <Helmet> 
                <meta charset="utf-8" /> 
                <title>Diamond Lab India - Elevating Optical Excellence for 70 Years</title>
                <meta name="description" content="Discover Diamondlab, a trusted name in optics for seven decades. Our unwavering commitment to customer satisfaction and swift deliveries sets us apart. Catering to Eye Care Professionals and corporate giants, we offer a one-stop-shop for cutting-edge optical machinery. Explore our range, including auto refractometers, lensmeters, edgers, and more. With three generations of expertise, Diamondlab is your go-to for top-tier eyecare machinery. Experience excellence – choose Diamondlab for precision and reliability." />
                <meta name="keywords" content="Optical Solutions India, Auto Refractometer Supplier, Precision Lensmeter, Cutting-edge Optical Equipment, Eyecare Machinery India, Diamondlab Optical Products, Optical Equipment Supplier, Reliable Auto Edgers, Eye Care Professionals India, Diamondlab Legacy Optics"/>
            </Helmet>
        <div class='px-3'>
            <div class='text-start'> 
                <p class='titleText fw-bold' data-aos="fade-up"> <span  style={{color:'#27448F'}}>ABOUT</span> <span>US</span> </p>
                 
            </div>
        </div>
        <div > 
            <div class='mt-5 col-12'>
                <div style={{position:'relative'}}> 
                    <div style={{ height:'70vh',clipPath:'inset(50% 0 0 0 round 25px 25px 0 0 )',backgroundColor:'#2A4BA3'}}>
 
                     </div>
                    <div class='px-3 w-100' style={{position:'absolute',top:'0',left:'0'}}> 
                        <div  style={{height:'68vh',width:'100%',borderRadius:'44px',overflow:'hidden'}} data-aos="zoom-in-up">
                            <img class='homeTopImg' src={require('../image/homePoster.jpg')} style={{width:'100%',height:'100%',objectFit:'cover',borderRadius:'44px'}} alt="" />
                        </div>
                    </div>
                    <div  style={{background:'#2A4BA3',clipPath: 'inset(0 0 0 0 round 0 0 25px 25px)',marginTop:'-2px'}}>
                        <div class='row gap-2 p-3 text-white'>
                            <div class='col-md-4 col-11 m-auto text-start mt-2'>
                                <p class='fs-1 fw-bold' data-aos="fade-up">Seven Decades of <br />Optical Excellence</p>
                            </div>
                            <div class='col-md-7 col-11 m-auto text-start'>
                                <p class='fs-5 tJustify mt-3 ' data-aos="fade-up">With a legacy spanning seven decades, Diamondlab stands as an unwavering pillar in the optical industry, known for its impeccable reputation and unwavering commitment to customer satisfaction. Our success is underpinned by our swift deliveries and exceptional after-sale services. </p>
                                <p class='fs-5 tJustify mt-3 ' data-aos="fade-up">Our distinguished clientele includes renowned Eye Care Professionals and corporate giants. We have earned their trust and loyalty through our consistent dedication to their optical machinery requirements. </p>
                                <p class='fs-5 tJustify mt-3 ' data-aos="fade-up">Diamondlab offers a comprehensive, one-stop-shop solution for all your optical machinery needs. Our product portfolio encompasses an extensive range of cutting-edge equipment, from autorefs and autolensmeters to auto edgers, manual edgers, PD meters, lens grovers, and much more. </p>
                                <p class='fs-5 tJustify mt-3 ' data-aos="fade-up">With three generations of technical expertise driving our company forward, Diamondlab is your ultimate destination for all your eyecare machinery requirements. Experience excellence in optical solutions, experience Diamondlab </p>

                            </div> 
                        </div>
                    </div>
                </div>
            </div>
           
        </div>
        <div class='bg-black py-4' style={{marginTop:'-20px' }}>
            <br />
            <br /> 
            <p class='display-3 text-white fw-bold' data-aos="fade-up">WHY YOU SHOULD <br /> CHOOSE US</p>
            <br />
            <div class='col-11 m-auto d-flex   flex-wrap align-items-center  justify-content-center  mt-5 gap-sm-4 gap-5'> 
                   <div class='rounded-4  bg-dark text-white text-start ' style={{position:'relative',width:'280px',height:'200px'}} data-aos="fade-up">
                    <div class='p-2 py-4'>
                        <p class='fs-5 fw-bold'>Trusted by Professionals</p>
                        <p class='mt-2'>Renowned Eye Care Partners
Join our loyal clientele of Eye Care Professionals and corporate leaders.</p>
                    </div>
                    <div style={{width:'30px',height:'30px',position:'absolute',top:'-15px',left:'30px'}}>
                        <img src={require('../image/cartIcon.png')} style={{width:'100%',height:'100%'}} alt="" />
                    </div>
                   </div>
                   
                   <div class='rounded-4  bg-dark text-white text-start' style={{position:'relative',width:'280px',height:'200px'}} data-aos="fade-up">
                    <div class='p-2 py-4'>
                        <p class='fs-5 fw-bold'>Comprehensive Solutions</p>
                        <p class='mt-2'>Your One-Stop Optical Machinery Source
Explore a diverse range of cutting-edge equipment for all your needs.</p>
                    </div>
                    <div style={{width:'30px',height:'30px',position:'absolute',top:'-15px',left:'30px'}}>
                        <img src={require('../image/cartIcon.png')} style={{width:'100%',height:'100%'}} alt="" />
                    </div>
                   </div>
                   <div class='rounded-4  bg-dark text-white text-start' style={{position:'relative',width:'280px',height:'200px'}} data-aos="fade-up">
                    <div class='p-2 py-4'>
                        <p class='fs-5 fw-bold'>Generations of Expertise</p>
                        <p class='mt-2'>Your Ultimate Eyecare Destination
Experience technical excellence with three generations of optical expertise.</p>
                    </div>
                    <div style={{width:'30px',height:'30px',position:'absolute',top:'-15px',left:'30px'}}>
                        <img src={require('../image/cartIcon.png')} style={{width:'100%',height:'100%'}} alt="" />
                    </div>
                   </div>
                   <div class='rounded-4  bg-dark text-white text-start' style={{position:'relative',width:'280px',height:'200px'}} data-aos="fade-up">
                    <div class='p-2 py-4'>
                        <p class='fs-5 fw-bold'>Unwavering Quality</p>
                        <p class='mt-2'>Excellence in Optical Solutions, Always at Diamondlab
Count on us for swift deliveries, exceptional after-sale services, and more.</p>
                    </div>
                    <div style={{width:'30px',height:'30px',position:'absolute',top:'-15px',left:'30px'}}>
                        <img src={require('../image/cartIcon.png')} style={{width:'100%',height:'100%'}} alt="" />
                    </div>
                   </div>
            </div>
            <br />
        </div> 
       

        <div style={{background:'#2A4BA3',marginTop:'-2px'}}> 
        <br /><br />
            <div class='row col-11 m-auto py-4 gap-lg-0 gap-4 text-lg-center text-start  ' > 
                <div class='col-lg-8 col-12 m-auto text-start'>
                    <p class='display-4 text-white fw-bold ' data-aos="fade-up">WANT TO KNOW MORE ABOUT OUR PRODUCTS PLEASE CONNECT.</p>
                </div>
                <div class='col-lg-4 col-12 m-auto '>
                    <div class=' text-primary' style={{width:'fit-content',borderRadius:'3px',background:'#ffffff' }} onClick={()=>nevigate('/request-quote')} > 
                        <Button class='p-2' name={'Get a Quote'}/>
                    </div>
                </div> 
            </div>
            <br /><br /> 
        </div>
    </div>
  )
}

export default About