import React, { useState } from 'react';
import './style.css';

const Button = ({name}) => {
  const [circleStyle, setCircleStyle] = useState({
    left: 0,
    top: 0,
    animationClass: '',
  });

  const handleMouseEnter = (e) => {
    const parentOffset = e.currentTarget.getBoundingClientRect();
    const relX = e.clientX - parentOffset.left;
    const relY = e.clientY - parentOffset.top;

    setCircleStyle({
      left: relX,
      top: relY,
      animationClass: 'explode-circle',
    });
  };

  const handleMouseLeave = () => {
    setCircleStyle({
      animationClass: 'desplode-circle',
    });
  };

  return (
    <div className="button_su  p-2">
      <span
        className={`su_button_circle ${circleStyle.animationClass}`}
        style={{ left: circleStyle.left, top: circleStyle.top }}
      ></span>
      <div
        className="button_su_inner"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <span className="button_text_container w-100">{name}</span>
      </div>
    </div>
  );
};

export default Button;
