import React, { useEffect } from 'react'
import { Customer, HomeTop } from './MultiCarousel';
import AOS from "aos";
import "aos/dist/aos.css";
import Button from './Button';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const machines = [
    { 
        style : {height :"200px"},
        img :  `${require('../image/gl300.png')}`  
    },
    {
        style : {height :"200px"},
        img : `${require('../image/gn9900.png')}`
    },
    {
        style : {height :"200px"},
        img : `${require('../image/gn200.png')}`
    },
    // {
    //     style : {height :"200px"},
    //     img : `${require('../image/le660a.png')}`
    // },
    {
        style : {height :"200px"},
        img : `${require('../image/chairUnit.png')}`
    },
    {
        style : {height :"200px"},
        img : `${require('../image/ark7600.png')}`
    },
    {
        style : {height :"200px"},
        img : `${require('../image/tl900.png')}`
    },
]


 
const customer = [
    {
        img : ` `,
        name :'Kyle Roberts DVM',
        post :'Customer Web Consultant',
        text :"Website design did exactly what you said it does. Just what I was looking for. Nice work on your website design.",
        star : 4
    },
    {
        img : ` `,
        name :'Sophia Anderson',
        post :'Internal Implementation Officer',
        text :"I will let my mum know about this, she could really make use of software! Very easy to use. Since I invested in software I made over 100,000 dollars profits. I just can't get enough of software. I want to get a T-Shirt with software on it so I can show it off to everyone.",
        star : 5
    },
    {
        img : '',
        name :'Stephen Brekke',
        post :'Legacy Integration Producer',
        text :"If you want real marketing that works and effective implementation - mobile app's got you covered.",
        star : 4
    }
]

const StarIcon = ({ count }) => { 
    return (
      <div>
        {Array.from({ length: count }, (_, index) => (
          <span key={index}><i class="bi bi-star-fill text-primary"></i></span>
        ))}
      </div>
    );
  };
  




const customerStotry = () =>{
    const arr = []
     for(let i = 0 ; i < customer.length ; i++){
        arr.push(
            <div style={{position:'relative'}}> 
                <div class='bg-white rounded-4'>
                    <div class='col-11 m-auto'> 
                        <div class='d-flex justify-content-end gap-3 pt-3'>
                            <StarIcon count={customer[i].star}/> 
                        </div>
                        <div class='my-3'>
                            <p class='tJustify'>{customer[i].text}</p>
                        </div>
                        <div class='d-flex justify-content-between  align-items-center pb-3  gap-1'>
                            <div class='text-start'>
                                <p class='fw-bold'>{customer[i].name}</p>
                                <p class='text-secondary' style={{fontSize:'14px'}}>{customer[i].post}</p> 
                            </div>
                            <div class='d-flex gap-2 align-items-center'>
                                <div class='p-1 bg-primary d-flex align-items-center justify-content-center ' style={{width:'25px',height:'25px',borderRadius:'50%'}}>
                                    <p  style={{fontSize:'14px'}}><i class="bi bi-hand-thumbs-up-fill text-white  "></i></p>
                                </div>
                                <p class='fw-bold'>Testimonial</p>
                            </div>
                        </div>
                     
                    </div> 
                </div>
                {/* <div style={{position:'absolute',width:'70px',height:'70px',borderRadius:'50%',top:'-35px',left:'35px'}}>
                    <img src={customer[i].img} style={{width:'100%',height:'100%',objectFit:'contain'}} alt="" />
                </div> */}
            </div>
        )
     }
     return arr;
}

const topSlider = () =>{
    const arr = []
     for(let i = 0 ; i < machines.length ; i++){
        arr.push( 
            <div style={machines[i].style}>
                <img src={machines[i].img} style={{height:'100%',objectFit:'contain'}} alt="" />
            </div> 
        )
     }
     return arr;
}
 
 

const Home = () => {
    useEffect(() => {
        AOS.init({ duration: 1500 });
      });

      const nevigate = useNavigate()
  return (
    <div> 
         <Helmet> 
                <meta charset="utf-8" /> 
                <title>Diamond Lab India - Pioneering Optical Solutions for Seven Decades</title>
                <meta name="description" content="Explore the legacy of Diamondlab, a stalwart in the optical industry for over seven decades. Renowned for its impeccable reputation and steadfast commitment to customer satisfaction, Diamondlab is the ultimate destination for Eye Care Professionals and corporate giants seeking cutting-edge optical machinery. Our comprehensive product portfolio, featuring state-of-the-art equipment such as auto refractometers, auto lensmeters, auto edgers, and more, reflects our dedication to excellence. Experience the pinnacle of optical solutions with Diamondlab – where tradition meets innovation."/>
                <meta name="keywords" content="Optical Machinery, Auto Refractometer, Auto Lensmeter, Auto Edger, Eye Care Professionals, Diamondlab India, Precision Optical Solutions, Optical Equipment Supplier, Legacy in Optics, Eyecare Machinery"/>
            </Helmet>
        <div class='px-3'>  
            <div class='text-start'> 
                <p class='titleText fw-bold' style={{color:'#27448F'}} data-aos="fade-up">EYECARE</p>
                <p class='titleText fw-bold' style={{color:''}} data-aos="fade-up">MACHINES</p> 
            </div>
             
            <div class='my-5 col-12 rounded-5 ' style={{background: 'linear-gradient(242deg, #5373C6 14.62%, #27448F 94.14%)'}}>
                <div style={{height:'100px'}}></div>
                <div class='col-11 m-auto'> 
                    <HomeTop data={topSlider()}/>
                </div>
                <div style={{height:'100px'}}></div> 
            </div>
            <div class=''>
                <p class='display-3 fw-bold text-start col-lg-8 col-md-10 col-12 py-4' data-aos="fade-up">THE FUTURE OF EYE CARE WITH <span style={{color:'#27448F'}}>OUR MACHINES.</span></p>
                <div class='row gap-sm-0 gap-3 '>
                    <div class='col-md-6 col-sm-4 text-start '>
                        <div class='border border-primary text-primary' style={{width:'fit-content',borderRadius:'3px',background:'#ffffff' }} onClick={()=>nevigate('/about')} > 
                            <Button name={'Know More About us'}/>
                        </div>
                    </div> 
                </div>
            </div>
            <div class='my-5'>
                <p class='display-1 fs-xl fw-bold' data-aos="fade-up">PRODUCT</p>
                <p class='display-1 fw-bold' style={{color:'#27448F'}} data-aos="fade-up">CATEGORIES</p>  
            </div>

            <div class='row'>
                <div class='col-lg-4 col-md-6 col-12 p-3  ' >
                    <div class=' m-auto rounded-5 h-100 ' style={{background:'#EDF0FA'}} > 
                        <div class='col-11 m-auto text-start py-4 productBox'> 
                            <div>
                                <p class='fs-2 fw-bold'>Grand Nippon GN-9900 Auto Refractometer</p> 
                                <a href="" onClick={()=>nevigate('/gn-9900')}>Read More</a>
                            </div>
                             
                            <div class='col-md-10 col-12 m-auto mt-5 imgIffect d-flex align-content-center justify-content-center  '>
                                <img class='' src={require('../image/gn9900.png')} style={{height:'250px'}} alt="" data-aos="zoom-out" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class='col-lg-8 col-md-6 col-12 p-3'>
                     <div class='m-auto rounded-5 h-100' style={{background:'linear-gradient(314deg, #27448F 0%, #2C52B3 100%)'}}> 
                        <div class='col-11 m-auto text-start py-4 row h-100 productBox'> 
                            <div class='col-lg-6 col-12'>
                                <p class='fs-2 fw-bold text-white'>AR/K-7600 Auto Refractokeratometer - Precise, Reliable, and Efficient</p>
                                <a href="" class='text-warning ' onClick={()=>nevigate('/auto-refractokeratometer')}>Read More</a>
                            </div>
                            <div class='col-lg-6 col-12 d-flex align-items-end justify-content-center imgIffect '>
                                <img src={require('../image/ark7600.png')} style={{height:'300px'}} alt="" data-aos="zoom-out" />
                            </div>
                        </div>
                    </div>
                </div>


            </div>

            <div class='row '>
                <div class='col-md-6  col-12 p-3'>
                    <div class='  row rounded-5 text-start m-auto'  style={{background:'#5383FF'}} >
                        <div class='row col-11 m-auto py-4 productBox'>

                        <div class='col-lg-6 col-12'>
                            <p class='fs-2 fw-bold text-white'>GL 300 Auto Lens meter</p>
                            <p class='text-white fs-5 my-3'>Futuristic Hi-Tech Auto Lensmeter with Highest Level of Accuracy, Speed, and Ease of Operation</p>
                            <a href="" class='text-warning' onClick={()=>nevigate('/gl-300')}>Read More</a>
                        </div>
                        <div class='col-lg-6 col-12 d-flex align-items-center justify-content-center imgIffect'>
                            <img src={require('../image/gl300.png')} style={{height:'300px'}} alt="" data-aos="zoom-out" />
                        </div>
                        </div>
                    </div>
                </div>
                <div class='col-md-6  col-12 p-3 h-100'>
                    <div class='  row rounded-5 text-start m-auto'  style={{background:'#000000'}} >
                        <div class='row col-11 m-auto py-4 productBox'>

                        <div class='col-lg-6 col-12'>
                            <p class='fs-2 fw-bold text-warning mb-3'>Visiotech Auto Lensmeter TL900 - Accurate, Easy-to-Use, and Versatile</p>
                            
                            <a   href="" class='text-warning  ' onClick={()=>nevigate('/tl-900')}>Read More</a>
                        </div>
                        <div class='col-lg-6 col-12 d-flex align-items-center justify-content-center  imgIffect'>
                            <img src={require('../image/tl900.png')} style={{height:'300px'}} alt="" data-aos="zoom-out" />
                        </div>
                        </div>
                    </div>
                </div>

            </div>

            <div class='row'>
                <div class='col-lg-4 col-md-6 col-12 p-3'>
                    <div class=' m-auto rounded-5 py-4 h-100 row text-start productBox' style={{background:'#D6C426'}} >
                        <div class='col-11 m-auto'>
                            <p class='fs-2 fw-bold text-black mb-3'>Grand Nippon GN-200 Auto Refractometer</p>
                            <a href="" class='text-black ' onClick={()=>nevigate('/gn-200')}>Read More</a>
                        </div>
                        <div class='col-12  imgIffect d-flex align-items-end justify-content-center'>
                            <img src={require('../image/gn200.png')} style={{height:'300px'}} alt="" data-aos="zoom-out" />
                        </div>
                    </div>
                </div>
                <div class='col-lg-4 col-md-6 col-12 p-3'>
                    <div class=' m-auto rounded-5 py-4 h-100 row text-start productBox' style={{background:'#000000'}} >
                        <div class='col-11 m-auto'>
                            <p class='fs-2 fw-bold text-white mb-3'>LE-660A Auto Lens Edger: Precise, Efficient, and Versatile</p>
                            <a href="" class='text-warning ' onClick={()=>nevigate('/le-660a')}>Read More</a>
                        </div>
                        <div class='col-12  imgIffect d-flex align-content-center   justify-content-md-end'>
                            <img src={require('../image/le660a.png')} style={{height:'300px'}} alt=""  data-aos="zoom-out"/>
                        </div>
                    </div>
                </div>
                <div class='col-lg-4 col-md-6 col-12 p-3'>
                    <div class=' m-auto rounded-5 py-4 h-100 row text-start productBox' style={{background:'#011C64'}} >
                        <div class='col-11 m-auto'>
                            <p class='fs-2 fw-bold text-white mb-3'>Optician's Refraction Chair Unit - Compact and Versatile</p>
                            <a href="" class='text-warning ' onClick={()=>nevigate('/refraction')}>Read More</a>
                        </div>
                        <div class='col-12 imgIffect d-flex align-content-center justify-content-center '>
                            <img src={require('../image/chairUnit.png')} style={{height:'300px'}} alt="" data-aos="zoom-out" />
                        </div>
                    </div>
                </div>

            </div>
        </div>
{/* 
        <div  class='mt-5' style={{background:'#EDF0FA'}}>
            <div class='col-11 m-auto py-5'> 
                <p class='display-4 fw-bold' data-aos="fade-up">CUTOMER</p>
                <p class='display-4 fw-bold' style={{color:'#27448F'}} data-aos="fade-up">SUCCESS STORIES</p>
                <p class='col-md-5 col-11 m-auto fs-5' data-aos="fade-up">Hear from our satisfied B2B clients and learn how our optical machines have transformed their businesses.</p>
            </div> 
        </div>
        <div style={{background:'linear-gradient(#EDF0FA 40%,#2A4BA3 0)'}}>
            <div class='col-11 m-auto'>

                 <Customer data={customerStotry()}/> 
            </div>
        </div> */}
        <div style={{background:'#2A4BA3',marginTop:'100px'}}> 
        <br /><br />
            <div class='row col-11 m-auto py-4 gap-lg-0 gap-4 text-lg-center text-start  ' > 
                <div class='col-lg-8 col-12 m-auto text-start'>
                    <p class='display-4 text-white fw-bold ' data-aos="fade-up">WANT TO KNOW MORE ABOUT OUR PRODUCTS PLEASE CONNECT.</p>
                </div>
                <div class='col-lg-4 col-12 m-auto '>
                    <div class=' text-primary' style={{width:'fit-content',borderRadius:'3px',background:'#ffffff' }} onClick={()=>nevigate('/request-quote')} > 
                        <Button class='p-2'  name={'Get a Quote'}   />
                    </div>
                </div> 
            </div>
            <br /><br /><br />
        </div>
    </div>
  )
}

export default Home