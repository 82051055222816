import React, { useEffect, useState } from 'react'
import AOS from "aos";
import "aos/dist/aos.css";
import { BsFacebook, BsInstagram, BsTwitter, BsYoutube } from 'react-icons/bs';
import Button from './Button';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import axios from 'axios';

const Contact = () => {

    const [contact, setContact] = useState({})
    useEffect(() => {
        AOS.init({ duration: 1500 });
      });

      const navigate = useNavigate()

      const contactInfo = (el)=>{
        const {name, value} = el.target 
        setContact({...contact,[name]:value})
      }

      const sendContactInfo =()=>{
        if(contact?.name && contact?.name != "" &&
        contact?.phone && contact?.phone != "" &&
        contact?.email && contact?.email != "" && 
        contact?.product && contact?.product != "" &&  
        contact?.message && contact?.message != "" ){ 
                axios.post(`https://hi-labsolution.net/api/diamond-contact.php`,contact)
                .then((res)=>{ 
                    if(res.data.responseStatus == true){
                        Swal.fire({
                          position: "center",
                          icon: "success",
                          title: `${res.data.responseMessage}`,
                          showConfirmButton: false,
                          timer: 2000,
                        })
                        setContact({
                            "name" : '',
                            'phone':'',
                            'email': '', 
                            'product':'',
                            'message':'' 
                        })
                    }else{
                        Swal.fire({
                            position: "center",
                            icon: "error",
                            title: `${res.data.responseMessage}`,
                            showConfirmButton: false,
                            timer: 2000,
                          })
                    }
                }).catch((err)=>{
                    console.log(err);
                })
            
        }else{
            Swal.fire({
                position: "center",
                icon: "error",
                title: `All fields must be required!`,
                showConfirmButton: false,
                timer: 2000,
              })
        }
      }


  return (
    <div>
        <div class='px-3'>
            <div class='text-start'> 
                <p class='titleText fw-bold' style={{color:'#27448F'}} data-aos="fade-up">CONTACT</p>
                <div class='d-lg-flex d-grid gap-4'> 
                    <p class='titleText fw-bold' style={{color:''}} data-aos="fade-up">US</p> 
                    <div class='d-flex align-items-center justify-content-center mt-3'> 
                        <div class='rounded-5 bg-primary row w-100 p-4 gap-4 m-auto h-auto '  data-aos="fade-up" style={{height:'fit-contant'}}>
                            <div class='col-lg-3 col-md-5 col-12'>
                                <p class='text-white'><span><i class="bi bi-telephone-fill fw-bold"></i></span> <span class='mx-2'>Call</span></p>
                                <p class='text-white mt-2'>+91 9375040808</p>
                                <p class='text-white'>diamondlabsales@gmail.com</p> 
                            </div>
                            <div class='col-lg-4 col-md-5 col-12'>
                                <p class='text-white'><span><i class="bi bi-geo-alt-fill"></i></span> <span class='mx-2'>Address</span></p>
                                <p class='text-white mt-2'>Opp Municipal Hopital, Station Road, Anand Gujrat, 388001</p>
    
                            </div>
                            <div class='col-lg-4 col-md-5 col-12'>
                                <p class='text-white'> <span class='mx-2'>Follow</span></p>
                                <div class='d-flex gap-3 flex-wrap text-white fs-3 mt-2'>
                                    <div  style={{width:'50px',height:'50px',cursor:'pointer',display:'flex',alignItems:'center',justifyContent:'center',borderRadius:'50%',border:'1px solid white'}}>
                                        <BsFacebook/>
                                    </div>
                                    <div  style={{width:'50px',height:'50px',cursor:'pointer',display:'flex',alignItems:'center',justifyContent:'center',borderRadius:'50%',border:'1px solid white'}}>
                                        <BsYoutube/>
                                    </div> 
                                    <div  style={{width:'50px',height:'50px',cursor:'pointer',display:'flex',alignItems:'center',justifyContent:'center',borderRadius:'50%',border:'1px solid white'}}>
                                        <BsTwitter/>
                                    </div>      
                                    <div  style={{width:'50px',height:'50px',cursor:'pointer',display:'flex',alignItems:'center',justifyContent:'center',borderRadius:'50%',border:'1px solid white'}}>
                                        <BsInstagram/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>  
        </div>
        <div class='py-4' style={{background:'linear-gradient(white 40%,#2A4BA3 0)'}}>
             
            <div class='mx-3 aboutBg p-3 row gap-5' data-aos="zoom-in">
                <div class='col-md-7  d-flex align-items-center justify-content-center '> 
                    <div class='w-100'> 
                        <div class='row ' >
                            <div class="col-md-6 mt-3">
                                <input type="text" class='form-control rounded-4 p-2 px-3' placeholder='Full Name' name='name' value={contact?.mane} onChange={contactInfo}  /> 
                            </div>
                            <div class="col-md-6 mt-3">
                                <input type="text" class='form-control rounded-4 p-2 px-3'placeholder='Select Product' name='product' value={contact?.product} onChange={contactInfo}  /> 
                            </div> 
                        </div>
                        <div class='row '>
                            <div class="col-md-6 mt-3">
                                <input type="text" class='form-control rounded-4 p-2 px-3' placeholder='Email' name='email' value={contact?.email} onChange={contactInfo}  /> 
                            </div>
                            <div class="col-md-6 mt-3">
                                <input type="text" class='form-control rounded-4 p-2 px-3' placeholder='Phone Number' name='phone' value={contact?.phone} onChange={contactInfo}  /> 
                            </div> 
                        </div>
                        <div class=''> 
                            <textarea class='form-control mt-3 rounded-4 p-2 px-3' rows="3" placeholder='Your Message' name='message' value={contact?.message} onChange={contactInfo}  ></textarea>
                      
                        </div>
                        <div class='d-flex justify-content-end mt-3'>
                            <div class='border text-white bg-primary-emphasis' style={{width:'fit-content',borderRadius:'3px',background:'#27448F' }} onClick={sendContactInfo} > 
                                <Button name={'Send Message'} />
                            </div>
                        </div>
                    </div> 
                   
                </div>
                <div class='col-md-4 py-3'>
                    <div class='col-md-10 m-auto' data-aos="zoom-in"> 
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7369.274031107789!2d72.95690143878228!3d22.55526627624118!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e4f7578ad4109%3A0x2481199bbf650b3!2sMunicipal%20Hospital%20Anand!5e0!3m2!1sen!2sin!4v1698660998542!5m2!1sen!2sin" width="100%" height="300" style={{border:'0',borderRadius:'25px'}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </div> 
            </div>
        </div>
        <div style={{background:'#2A4BA3',marginTop:'-2px'}}> 
        <br /><br />
            <div class='row col-11 m-auto py-4 gap-lg-0 gap-4 text-lg-center text-start  ' > 
                <div class='col-lg-8 col-12 m-auto text-start'>
                    <p class='display-4 text-white fw-bold ' data-aos="fade-up">WANT TO KNOW MORE ABOUT OUR PRODUCTS PLEASE CONNECT.</p>
                </div>
                <div class='col-lg-4 col-12 m-auto '>
                    <div class=' text-primary' style={{width:'fit-content',borderRadius:'3px',background:'#ffffff' }} onClick={()=>navigate('/request-quote')} > 
                        <Button class='p-2' name={'Get a Quote'}/>
                    </div>
                </div> 
            </div>
            <br /><br /><br />
        </div>
    </div>
  )
}

export default Contact