 
import './App.css';
import Navbar from './components/Navbar';
import Home from './components/Home';
import Footer from './components/Footer';
import { Route, Routes } from 'react-router-dom';
import Contact from './components/Contact';
import About from './components/About';
import Product from './components/Product';
import ScrollToTop from './components/ScrollToTop';
 
import RequetQuate from './components/RequetQuate';
import { Button } from 'bootstrap';
import GL300 from './components/products/GL300';
import GN9900 from './components/products/GN9900';
import GN200 from './components/products/GN200';
import LE660 from './components/products/LE660';
import Refraction from './components/products/Refraction';
import AutoRefraction from './components/products/AutoRefraction';
import TL900 from './components/products/TL900';
import MiniProducts from './components/MiniProducts';
import AutoRefractometer from './components/AutoRefractometer';
import AutoLensmeter from './components/AutoLensmeter';
import OtherMachines from './components/OtherMachines';
 
 

function App() {
  return (
    <div className="App">
      <ScrollToTop/>
       <Navbar/>
       <div class='' style={{marginTop:'100px'}}> 
       <Routes> 
     
       <Route path='/' element={<Home/>}/>
       <Route path='/contact' element={<Contact/>}/>
       <Route path='/about' element={<About/>}/>
       <Route path='/all-products' element={<Product/>}/>
     
       <Route path='/request-quote' element={<RequetQuate/>}/>
       <Route path='/gl-300' element={<GL300/>}/>
       <Route path='/gn-9900' element={<GN9900/>}/> 
       <Route path='/gn-200' element={<GN200/>}/> 
       <Route path='/le-660a' element={<LE660/>}/>
       <Route path='/refraction' element={<Refraction/>}/>
       <Route path='/ark-7600' element={<AutoRefraction/>}/>
       <Route path='/tl-900' element={<TL900/>}/>
       <Route path='/auto-refractometer' element={<AutoRefractometer/>}/>
       <Route path='/auto-lensmeter' element={<AutoLensmeter/>}/>
       <Route path='/other-machines' element={<OtherMachines/>}/>
       <Route path='/small-machines' element={<MiniProducts/>}/>





     





          
       </Routes>
       </div>
       <Footer/>
    </div>
  );
}

export default App;
