import React, { useEffect, useState } from 'react'
import AOS from "aos";
import "aos/dist/aos.css";
import '.././style.css'
import { Benefits, Customer, Features } from '../MultiCarousel';
import { FiChevronDown, FiChevronLeft } from 'react-icons/fi';
import Button from '../Button';
import { Link } from 'react-scroll';
import { useNavigate } from 'react-router-dom';
 

const customer = [
    {
        img : ``,
        name :'Kyle Roberts DVM',
        post :'Customer Web Consultant',
        text :"Website design did exactly what you said it does. Just what I was looking for. Nice work on your website design.",
        star : 4
    },
    {
        img : ``,
        name :'Sophia Anderson',
        post :'Internal Implementation Officer',
        text :"I will let my mum know about this, she could really make use of software! Very easy to use. Since I invested in software I made over 100,000 dollars profits. I just can't get enough of software. I want to get a T-Shirt with software on it so I can show it off to everyone.",
        star : 5
    },
    {
        img : ``,
        name :'Stephen Brekke',
        post :'Legacy Integration Producer',
        text :"If you want real marketing that works and effective implementation - mobile app's got you covered.",
        star : 4
    }
]

const StarIcon = ({ count }) => {
    // Replace this with your star icon component, e.g., <span>*</span> or an SVG icon
    return (
      <div>
        {Array.from({ length: count }, (_, index) => (
          <span key={index}><i class="bi bi-star-fill text-primary"></i></span>
        ))}
      </div>
    );
  };  
const customerStotry = () =>{
    const arr = []
     for(let i = 0 ; i < customer.length ; i++){
        arr.push(
            <div style={{position:'relative'}}> 
                <div class='bg-white rounded-4'>
                    <div class='col-11 m-auto'> 
                        <div class='d-flex justify-content-end gap-3 pt-3'>
                            <StarIcon count={customer[i].star}/> 
                        </div>
                        <div class='my-3'>
                            <p class='tJustify'>{customer[i].text}</p>
                        </div>
                        <div class='d-flex justify-content-between  align-items-center pb-3  gap-1'>
                            <div class='text-start'>
                                <p class='fw-bold'>{customer[i].name}</p>
                                <p class='text-secondary' style={{fontSize:'14px'}}>{customer[i].post}</p> 
                            </div>
                            <div class='d-flex gap-2 align-items-center'>
                                <div class='p-1 bg-primary d-flex align-items-center justify-content-center ' style={{width:'25px',height:'25px',borderRadius:'50%'}}>
                                    <p  style={{fontSize:'14px'}}><i class="bi bi-hand-thumbs-up-fill text-white  "></i></p>
                                </div>
                                <p class='fw-bold'>Testimonial</p>
                            </div>
                        </div>
                     
                    </div> 
                </div>
                {/* <div style={{position:'absolute',width:'70px',height:'70px',borderRadius:'50%',top:'-35px',left:'35px'}}>
                    <img src={customer[i].img} style={{width:'100%',height:'100%',objectFit:'contain'}} alt="" />
                </div> */}
            </div>
        )
     }
     return arr;
}

const features=[
    {
        title :'Fast, accurate measurement:',
        text:'Unique optical technology and highly sensitive CCD for fast and accurate measurement'
    },
    {
        title :'Wide measurement range:',
        text:'Wide measurement range of astigmatism, spherical and cylinder values'
    },
    {
        title :'Small pupil measurement:',
        text:'Minimum pupil diameter measurable up to 2.0 mm, smallest in the world'
    },
    {
        title :'Easy to use:',
        text:'5.7&quot; inch Color Liquid Crystal Display for Easy to view, Elegant and Better look. PD is automatically measured after checking both eyes. 10 Measurement values can be memorized for each right and left eye.'
    },
    
    
]

const specification =[
    {
        title :'Printer:',
        text:'Built-in'
    },
    {
        title :'Monitor:',
        text:'5.7" TFT LCD'
    },
    {
        title :'Power:',
        text:'AC 100-240V, 50/60 Hz'
    },
    {
        title :'Dimensions',
        text:'480 (L) x 282 (W) x 445 (H) mm'
    },
    {
        title :'Weight:',
        text:'20 kg'
    }, 
]

const keratometrySpecification =[
    {
        title :'Radius of curvature:',
        text:'5.0 10.2 mm (Steps: 0.01 mm)'
    },
    {
        title :'Corneal refractometry:',
        text:'33-67.50 D (Steps: 0.05 D. 0.12 D. 0.25 D'
    },
    {
        title :'Corneal astigmatism:',
        text:'0.0-8.00 D (steps: 0.12D, 0.25 D)'
    },
    {
        title :'Corneal diameter:',
        text:'2.0 mm 14.0 mm (Steps: 0.1 mm)'
    },
     
]

const benefits =[
    {
        title :'Fast, accurate refraction',
        text:' '
    },
    {
        title :'Easy to use, efficient',
        text:' '
    },
    {
        title :'Wide range of measurements, versatile',
        text:' '
    }, 
]


const GN9900 = () => {
    const [productEnter,setProductEnter] = useState(false)

    const [proDetails,setProDetails] = useState('about')
    useEffect(() => {
        AOS.init({ duration: 1500 });
      });
      const navigate = useNavigate()

  return (
    <div>
        <div class='px-3'>
        <div class='mt-5 col-12 rounded-5 d-grid align-content-center ' style={{position:'relative',background: 'linear-gradient(242deg, #5373C6 14.62%, #27448F 94.14%)',height:'80vh'}}>
                 
                 <div class='d-flex text-start align-items-center h-100'> 
                     <div> 
                         <p class='BgText'>GN <br />9900</p>
                     </div>
                 </div>
 
                 <div style={{position:'absolute',top:'0',left:'0',width:'100%',height:'100%' }}>
                 <div class='row h-100 '>
                     <div class='  col-md-6 col-11 d-flex justify-content-md-end justify-content-center align-items-center my-3'>
                         <div class='' style={{height:'300px'}}>
                             <img src={require('../../image/gn9900.png')} style={{height:'100%'}} alt="" />
                         </div>
                     </div>
                     <div class=' col-md-6 col-11 d-flex align-content-center my-3'>
                         <div class='col-11 m-auto text-start'>
                              <p class='display-3 fw-semibold text-white '>Grand Nippon GN-9900 AUTO REFRACTOMETER</p>
                         </div>
                     </div>
 
                 </div>
                 </div>
             </div>
            <div>
                <div class='row'>
                    <div class='col-lg-9'>
                        <div class='row p-3'> 
                            <div class='col-lg-6 d-flex gap-4 flex-wrap align-items-center justify-content-around productDetail' data-aos="fade-up">
                            <Link
                                activeClass="active"
                                to="about"
                                spy={true}
                                smooth={true}
                                offset={-70}
                                duration={500}
                                style={{
                                textDecoration: "none",
                                color: "black",
                                cursor: "pointer",
                                }}
                                onClick={()=>setProDetails('about')} 

                             >
                                <p class='options' style={proDetails == 'about' ?{textDecorationLine:"underline",textUnderlineOffset:"5px", cursor:'pointer',textDecorationColor: '#27448F',textDecorationThickness:'3px'}:{}}>About</p>
                                </Link>
                                <Link
                                activeClass="active"
                                to="features"
                                spy={true}
                                smooth={true}
                                offset={-70}
                                duration={500}
                                style={{
                                textDecoration: "none",
                                color: "black",
                                cursor: "pointer",
                                }}
                                onClick={()=>setProDetails('features')} 

                             >
                                <p class='options' style={proDetails == 'features' ?{textDecorationLine:"underline",textUnderlineOffset:"5px", cursor:'pointer',textDecorationColor: '#27448F',textDecorationThickness:'3px'}:{}}>Features</p>
                                </Link>
                                <Link
                                activeClass="active"
                                to="specification"
                                spy={true}
                                smooth={true}
                                offset={-70}
                                duration={500}
                                style={{
                                textDecoration: "none",
                                color: "black",
                                cursor: "pointer",
                                }}
                                onClick={()=>setProDetails('specification')} 

                             >
                                <p class='options' style={proDetails == 'specification' ?{textDecorationLine:"underline",textUnderlineOffset:"5px", cursor:'pointer',textDecorationColor: '#27448F',textDecorationThickness:'3px'}:{}}>Specification</p>
                                </Link>
                                <Link
                                activeClass="active"
                                to="benefits"
                                spy={true}
                                smooth={true}
                                offset={-70}
                                duration={500}
                                style={{
                                textDecoration: "none",
                                color: "black",
                                cursor: "pointer",
                                }}
                                onClick={()=>setProDetails('benefits')} 
                             >
                                <p class='options' style={proDetails == 'benefits' ?{textDecorationLine:"underline",textUnderlineOffset:"5px", cursor:'pointer',textDecorationColor: '#27448F',textDecorationThickness:'3px'}:{}}>Benefits</p>
                                </Link>
                                <Link
                                activeClass="active"
                                to="speciality"
                                spy={true}
                                smooth={true}
                                offset={-70}
                                duration={500}
                                style={{
                                textDecoration: "none",
                                color: "black",
                                cursor: "pointer",
                                
                                }}
                                onClick={()=>setProDetails('speciality')}
                             >
                                <p class='options' style={proDetails == 'speciality' ?{textDecorationLine:"underline",textUnderlineOffset:"5px", cursor:'pointer',textDecorationColor: '#27448F',textDecorationThickness:'3px'}:{}}>Speciality</p>
                                </Link>
                                
                            </div>
                            <div class='col-lg-6 d-flex gap-4 justify-content-center flex-wrap'>
                            <div  onClick={()=>navigate('/contact')}  class=' text-primary border-primary border' style={{width:'fit-content',borderRadius:'3px',background:'#ffffff' }} > 
                                    <Button class='p-2' name={'Enquiry For Price'}/>
                                </div>
                                <div  onClick={()=>navigate('/request-quote')}  class=' text-white' style={{width:'fit-content',borderRadius:'3px',background:'#27448F' }} > 
                                    <Button class='p-2' name={'Request a Demo'}/>
                                </div>
                                {/* <button class='p-2 px-3 rounded-1 fw-semibold btn btn-outline-primary' data-aos="fade-up" >Request a Quote</button>
                                <button class='p-2 px-3 rounded-1 fw-semibold btn text-white' data-aos="fade-up" style={{background:'#27448F'}} >Request a Quote</button> */}

                            </div>
                        </div>
                        <div class='text-start col-11  m-auto about'> 
                            <p class='fs-1 fw-semibold' data-aos="fade-up">Grand Nippon GN-9900 AUTO REFRACTOMETER</p>
                            <p class='text-secondary col-md-10 tJustify' data-aos="fade-up">The Grand Nippon GN-9900 AUTO REFRACTOMETER is a state-of-the-art refractometer that provides fast, accurate, and easy-to-use refraction measurements. It is ideal for use in a variety of settings, including optometry offices, optical shops, ophthalmic and research institutions.</p>
                        </div>

                        <div class='text-start col-11 m-auto  my-5 features'> 
                            <p class='fs-1 fw-semibold' data-aos="fade-up">Features</p>
                            <div style={{border:'2px solid #103493',width:'60px',borderRadius:'25px'}} data-aos="fade-up"></div>
                            <div class='my-4'> 
                                <Features data={features}/>
                            </div>
                        </div>

                        <div class='text-start col-11 m-auto my-5 specification'>
                            <p class='fs-1 fw-semibold' data-aos="fade-up">Specification</p>
                            <div style={{border:'2px solid #103493',width:'60px',borderRadius:'25px'}} data-aos="fade-up"></div>
                            
                            <div class='my-4'>
                                {
                                   specification.map((el)=>{
                                    return(
                                        <div class='my-2' style={{clipPath:' polygon(0% 0%, 97% 0, 100% 50%, 97% 100%, 0% 100%)',background:'#f5f5ff'}} data-aos="fade-up">
                                            <p class='p-2 px-3 '><b>{el.title}</b> {el.text}</p>
                                        </div>
                                    )
                                   }) 
                                }
                            </div> 
                           
                        </div>

                        <div class='text-start col-11 m-auto my-5  '>
                            <p class='fs-1 fw-semibold' data-aos="fade-up">Keratometry Specification</p>
                            <div style={{border:'2px solid #103493',width:'60px',borderRadius:'25px'}} data-aos="fade-up"></div>
                            
                            <div class='my-4'>
                                {
                                   keratometrySpecification.map((el)=>{
                                    return(
                                        <div class='my-2' style={{clipPath:' polygon(0% 0%, 97% 0, 100% 50%, 97% 100%, 0% 100%)',background:'#f5f5ff'}} data-aos="fade-up">
                                            <p class='p-2 px-3 '><b>{el.title}</b> {el.text}</p>
                                        </div>
                                    )
                                   }) 
                                }
                            </div> 
                           
                        </div>

                        <div class='text-start col-11 m-auto my-5 benefits'>
                            <p class='fs-1 fw-semibold' data-aos="fade-up">Benefits</p>
                            <div style={{border:'2px solid #103493',width:'60px',borderRadius:'25px'}} data-aos="fade-up"></div>
                            
                            <div class='my-4'> 
                                <Benefits data={benefits}/>
                            </div>
                        </div>

                        <div class='text-start col-11 m-auto my-5 benefits'>
 
                            
                            <div class='' data-aos="fade-up"> 
                                <p class='fw-semibold my-4'>Order your Grand Nippon GN-9900 AUTO REFRACTOMETER today and experience the difference!</p>
                                <div class=' text-white' style={{width:'fit-content',borderRadius:'3px',background:'#27448F' }} onClick={()=>navigate('/request-quote')} > 
                                    <Button class='p-2' name={'Request a Demo'}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class='col-lg-3 p-3 h-auto'>
                        <div class='rounded-3 p-2' style={{background:'#F5F5FF'}} data-aos="fade-up">
                        <div class='col-10 m-auto text-start py-3'>

<p class='fs-3 fw-bold'>All Products</p> 
<hr />
<p class='fw-bold productList' style={{cursor:'pointer'}} onClick={()=>navigate('/gl-300')}>GL 300 Auto Lens meter</p>
<hr />
<p class='fw-bold productList' style={{cursor:'pointer',color:'blue'}} onClick={()=>navigate('/gn-9900')}>Grand Nippon GN-9900 AUTO REFRACTOMETER</p>
<hr />
<p class='fw-bold productList' style={{cursor:'pointer'}} onClick={()=>navigate('/gn-200')}>Grand Nippon GN-200 Auto Refractometer</p>
<hr />
<p class='fw-bold productList' style={{cursor:'pointer'}} onClick={()=>navigate('/le-660a')}>LE-660A Auto Lens Edger: Precise, Efficient, and Versatile</p>
<hr />
<p class='fw-bold productList' style={{cursor:'pointer'}} onClick={()=>navigate('/refraction')}>Optician&#39;s Refraction Chair Unit - Compact and Versatile</p>
<hr />
<p class='fw-bold productList' style={{cursor:'pointer'}} onClick={()=>navigate('/ark-7600')}>AR/K-7600 Auto Refractokeratometer - Precise, Reliable, and Efficient</p>
<hr />
<p class='fw-bold productList' style={{cursor:'pointer'}} onClick={()=>navigate('/tl-900')}>Visiotech Auto Lensmeter TL900 - Accurate, Easy-to-Use, and Versatile</p>

</div>  
                        </div>
                            <br />
                    </div>

                </div>
            </div>
            {/* <div class='speciality'>
                <p class='display-3 fw-bold' data-aos="fade-up">Related Product</p>
                <div class='row mt-5'>
                    <div class='col-lg-4 col-md-6 col-12 p-3'>
                        <div class=' m-auto rounded-5 py-4 h-100 row text-start productBox' style={{background:'#D6C426'}} >
                            <div class='col-11 m-auto'>
                                <p class='fs-2 fw-bold text-black'>Manual Lensmeter with LED Illumination</p>
                                <p class='text-black my-3'>The LM-8 lensmeter features an LED illuminated target that allows you to view and measure lenses precisely without glare.</p>
                                <a href="" class='text-black '>Read More</a>
                            </div>
                            <div class='col-12  imgIffect'>
                                <img src={require('../../image/m5.png')} style={{width:'100%'}} alt="" data-aos="zoom-out" />
                            </div>
                        </div>
                    </div>
                    <div class='col-lg-4 col-md-6 col-12 p-3'>
                        <div class=' m-auto rounded-5 py-4 h-100 row text-start productBox' style={{background:'#000000'}} >
                            <div class='col-11 m-auto'>
                                <p class='fs-2 fw-bold text-white'>Digital Phoropter CV-5000S</p>
                                <p class='text-warning my-3'>The CV-5000S makes refraction faster, offers an ergonomic alternative to manual refraction and elevates the patient experience.</p>
                                <a href="" class='text-warning '>Read More</a>
                            </div>
                            <div class='col-12  imgIffect'>
                                <img src={require('../../image/m6.png')} style={{width:'100%'}} alt=""  data-aos="zoom-out"/>
                            </div>
                        </div>
                    </div>
                    <div class='col-lg-4 col-md-6 col-12 p-3'>
                        <div class=' m-auto rounded-5 py-4 h-100 row text-start productBox' style={{background:'#011C64'}} >
                            <div class='col-11 m-auto'>
                                <p class='fs-2 fw-bold text-white'>Topographer Corneal Analyzer 800</p>
                                <p class='text-white my-3'>The CA-800 Corneal Analyzer is an easy-to-use corneal topographer with tools for complete evaluation of the anterior corneal surface and eyelids.</p>
                                <a href="" class='text-warning '>Read More</a>
                            </div>
                            <div class='col-12 imgIffect '>
                                <img src={require('../../image/m7.png')} style={{width:'100%'}} alt="" data-aos="zoom-out" />
                            </div>
                        </div>
                    </div>

                </div>
            </div>   */}
        </div>
        {/* <div  class='mt-5' style={{background:'#EDF0FA'}}>
            <div class='col-11 m-auto py-5'> 
                <p class='display-4 fw-bold' data-aos="fade-up">CUTOMER</p>
                <p class='display-4 fw-bold' style={{color:'#27448F'}} data-aos="fade-up">SUCCESS STORIES</p>
                <p class='col-md-5 col-11 m-auto fs-5' data-aos="fade-up">Hear from our satisfied B2B clients and learn how our optical machines have transformed their businesses.</p>
            </div> 
        </div>
        <div style={{background:'linear-gradient(#EDF0FA 40%,#2A4BA3 0)'}}>
            <div class='col-11 m-auto'> 
                 <Customer  data={customerStotry()}/> 
            </div>
        </div> */}
        <div style={{background:'#2A4BA3',marginTop:'-2px'}}> 
        <br /><br />
            <div class='row col-11 m-auto py-4 gap-lg-0 gap-4 text-lg-center text-start  ' > 
                <div class='col-lg-8 col-12 m-auto text-start'>
                    <p class='display-4 text-white fw-bold ' data-aos="fade-up">WANT TO KNOWW MORE ABOUT OUR PRODUCTS PLEASE CONNECT.</p>
                </div>
                <div class='col-lg-4 col-12 m-auto '>
                    <div class=' text-primary' style={{width:'fit-content',borderRadius:'3px',background:'#ffffff' }}  onClick={()=>navigate('/request-quote')} > 
                        <Button class='p-2' name={'Get a Quote'}/>
                    </div>
                </div> 
            </div>
            <br /><br /><br />
        </div>

    </div>
  )
}

export default GN9900