import React from 'react'
import { BsFacebook,BsYoutube,BsTwitter,BsInstagram  } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';

const Footer = () => {

    const navigate = useNavigate()
  return (
    <div style={{background:'black'}}>
        <div class='col-11 m-auto py-5 row text-white text-start'>
            <div class='col-md-6 col-12'>
                <img src={require('../image/logoFooter.png')}style={{height:'80px'}} alt="" />

                <div class='d-flex gap-3 flex-wrap my-4'>
                        <p class='text-white' style={{cursor:'pointer'}} onClick={()=>navigate('/')}>Home</p>
                        <p class='text-white' style={{cursor:'pointer'}} onClick={()=>navigate('/about')}>About</p>
                        <p class='text-white' style={{cursor:'pointer'}} onClick={()=>navigate('/all-products')}>All Products</p> 
                        <p class='text-white' style={{cursor:'pointer'}} onClick={()=>navigate('/contact')}>Contact Us</p>

                </div>
            </div>
            <div class='col-md-6 col-12 row'>
                <div class='col-sm-6 col-12'>
                    <p><i class="bi bi-geo-alt text-white fs-4"></i></p>
                    <p class='my-3'>Opp Municipal Hopital, Station Road, Anand Gujrat, 388001</p>
                    <p>+91 9375040808</p>
                    <p class='my-3'>diamondlabsales@gmail.com</p>
                </div>
                <div class='col-sm-6 col-12'>
                    <div class='d-flex gap-4 flex-nowrap fs-2'>
                        <BsFacebook/>
                        <BsYoutube/>
                        <BsTwitter/>
                        <BsInstagram/>
                    </div>
                </div> 
            </div>

        </div>
        <div class=' text-white col-11 m-auto p-2'>
            <div class='row gap-2'> 
                <div class='col-md-6  row gap-sm-0 gap-2'>
                    <div class='col-sm-6 text-start'>
                        <p>@2023 Copyright all right reserved</p>
                        <p class='mt-2'>Developed by : <span style={{color: 'rgb(128, 192, 77)', fontWeight: 'bold'}}> <a href="https://hi-labsolution.com/" target="_blank"><img src="https://hi-labsolution.com/hlogo.png" alt="Hi-Lab Solution"/></a> Hi Lab Solution </span></p>
                    </div>
                    <div class='col-sm-6 text-start'>
                        <p>Privacy Policy</p> 
                    </div> 
                </div>
        
                <div class='col-md-5 text-md-center text-start'>
                    <p>Terms & Conditions</p>
                </div>
            </div>

        </div>
    </div>
  )
}

export default Footer