import React, { useEffect, useState } from 'react'
import './style.css'
import { useNavigate } from 'react-router-dom';
import Button from './Button';

const Navbar = () => {
  const [isActive, setIsActive] = useState(false);
 


    useEffect(() => {
        const nav = document.querySelector(".navbarMain");
        let lastScrollY = window.scrollY;
        window.addEventListener("scroll", () => {
          if (lastScrollY < window.scrollY) {
            nav.classList.add("navbarMain--hidden");
          } else {
            nav.classList.remove("navbarMain--hidden");
          }
          lastScrollY = window.scrollY;
        });
      });

      useEffect(()=>{
        const nav = document.querySelector('.menus')
        nav.style.transition ="0.5s"
        nav.style.clipPath = 'inset(50% 0 50% 0)'
        if(isActive){
        nav.style.transition ="1s" 
        nav.style.clipPath = 'inset(0 0 0 0)' 
        } 
      },[isActive])

      const navigate = useNavigate()

      const movePage = (path)=>{
        setIsActive(false)
        navigate(path)
      }

   
  return (
    <div class='navbarMain'>
        <div class='col-11 d-flex justify-content-between align-items-center  m-auto p-2'> 
            <div>
                <img src={require('../image/logoNav.png')} style={{height:'55px',cursor:"pointer"}} alt="" onClick={()=>navigate('/')}/>
            </div>
            <div class='d-flex align-items-center gap-4'> 
                <div class='navPc'>  
                <div class='d-flex gap-4 align-items-center'> 
                  
                    <div style={{cursor:'pointer'}} onClick={()=>navigate('/')}>Home</div>
                    <div style={{cursor:'pointer'}} onClick={()=>navigate('/about')}>About</div>
                    <div class='navhover' style={{cursor:'pointer', position:'relative'}} >
                      All Products <span><i class="bi bi-caret-down-fill"></i></span>

                      <div class='navAllProducts' >
                        <div style={{height:'40px'}}></div>

                        <div class='navCategory text-start '> 
                            <div class='navCats'>  
                              <p class='p-2' style={{ cursor: 'pointer'}} onClick={()=>navigate('/all-products')}>All Products </p>
                            </div> 
                          </div>

                          <div class='navCategory text-start '> 
                            <div class='navCats' > 
                              <div class='navCatsAll text-start'>
                                <div class='navCatsAllDiv' style={{borderTop:'1px solid'}} onClick={()=>navigate('/gn-9900')}>
                                  <p class='p-2' style={{ cursor: 'pointer'}}>Grand Nippon GN-9900 AUTO REFRACTOMETER</p>
                                </div>
                                <div class='navCatsAllDiv' style={{borderTop:'1px solid'}} onClick={()=>navigate('/gn-200')}>
                                  <p class='p-2' style={{ cursor: 'pointer'}}>Grand Nippon GN-200 Auto Refractometer</p>
                                </div>
                                <div class='navCatsAllDiv' style={{borderTop:'1px solid'}} onClick={()=>navigate('/ark-7600')}>
                                  <p class='p-2' style={{ cursor: 'pointer'}}>AR/K-7600 Auto Refractokeratometer - Precise, Reliable, and Efficient</p>
                                </div>
                              </div>
                              <p class='p-2' style={{ cursor: 'pointer'}} onClick={()=>navigate('/auto-refractometer')}  >Auto Refractometer   <span style={{marginLeft:'10px'}}><i class="bi bi-caret-right-fill"></i></span></p>
                            </div>
 

                          </div>

                          <div class='navCategory text-start '> 
                            <div class='navCats'> 
                              <div class='navCatsAll text-start'>
                                <div class='navCatsAllDiv' style={{borderTop:'1px solid'}} onClick={()=>navigate('/gl-300')}>
                                  <p class='p-2' style={{ cursor: 'pointer'}}>GL 300 Auto Lens meter</p>
                                </div>
                                <div class='navCatsAllDiv' style={{borderTop:'1px solid'}} onClick={()=>navigate('/le-660a')}>
                                  <p class='p-2' style={{ cursor: 'pointer'}}>LE-660A Auto Lens Edger: Precise, Efficient, and Versatile</p>
                                </div>
                                <div class='navCatsAllDiv' style={{borderTop:'1px solid'}} onClick={()=>navigate('/tl-900')}>
                                  <p class='p-2' style={{ cursor: 'pointer'}}>Visiotech Auto Lensmeter TL900 - Accurate, Easy-to-Use, and Versatile</p>
                                </div>
                              </div>
                              <p class='p-2' style={{ cursor: 'pointer'}} onClick={()=>navigate('/auto-lensmeter')}>Auto Lensmeter <span style={{marginLeft:'10px'}}><i class="bi bi-caret-right-fill"></i></span></p>
                            </div>  
                          </div>

                          <div class='navCategory text-start '> 
                            <div class='navCats'>  
                              <p class='p-2' style={{ cursor: 'pointer'}} onClick={()=>navigate('/small-machines')}>Small Machines</p>
                            </div> 
                          </div>

                          <div class='navCategory text-start '> 
                            <div class='navCats'> 
                              <div class='navCatsAll text-start'>
                                <div class='navCatsAllDiv' style={{borderTop:'1px solid'}} onClick={()=>navigate('/refraction')}>
                                  <p class='p-2' style={{ cursor: 'pointer'}} >Optician's Refraction Chair Unit - Compact and Versatile</p>
                                </div> 
                              </div>
                              <p class='p-2' style={{ cursor: 'pointer'}} onClick={()=>navigate('/other-machines')} >Other Machines <span style={{marginLeft:'10px'}}><i class="bi bi-caret-right-fill"></i></span></p>
                            </div> 
                          </div>

                        
                      </div>
                    
                    </div>
                    {/* <div style={{cursor:'pointer'}} onClick={()=>navigate('/small-machines')}>Small Machine's </div> */}

                    <div style={{cursor:'pointer'}} onClick={()=>navigate('/contact')}>Contact Us</div>  
                    <div class='border text-white bg-primary-emphasis' style={{width:'fit-content',borderRadius:'3px',background:'#27448F' }} onClick={()=>navigate('/request-quote')}> 
                      <Button name={'Request a Quote'}/>
                    </div>
                </div>
                </div>    
                <div class='navMobile'>
                  <div class={isActive ? 'hamburger is-active' : 'hamburger'} id="hamburger" onClick={()=> setIsActive(!isActive)} >
                    <span class="line"></span>
                    <span class="line"></span>
                    <span class="line"></span>
                  </div>
                </div>
            </div>
        </div>

        <div class='menus d-flex justify-content-center align-items-center ' style={{width:'100%',height:'105vh',position:'fixed',top:'0',right:'0', zIndex: '1',background:'#294A9F', }}>
            <div class='d-grid gap-3   text-start col-sm-6 col-10 m-auto'>
                  <p class='fs-5 fw-bold text-white' style={{cursor:'pointer'}} onClick={()=>movePage('/')}>Home</p>
                  <p class='fs-5 fw-bold text-white' style={{cursor:'pointer'}} onClick={()=>movePage('/about')}>About</p>
                  <div class='mbAllProduct'>
                    <p class=' fs-5 fw-bold text-white ' style={{cursor:'pointer'}}  >All Products <span><i class="bi bi-caret-down-fill"></i></span></p>

                    <div class='mbProductCats'>

                    <div class='mbCats'>
                        <p class='text-white fs-5 fw-semibold mt-2'  onClick={()=>movePage('/all-products')}>All Products</p>
                      </div>

                      <div class='mbCats'>
                        <p class='text-white fs-5 fw-semibold mt-2'  >Auto Refractometer <span><i class="bi bi-caret-down-fill"></i></span></p>
                        <div class='mbCatsOption'>
                          <p class='text-white mt-2'  onClick={()=>movePage('/gn-9900')}>Grand Nippon GN-9900 AUTO REFRACTOMETER</p>
                          <p class='text-white mt-2'  onClick={()=>movePage('/gn-200')}>Grand Nippon GN-200 Auto Refractometer Features</p>
                          <p class='text-white mt-2'  onClick={()=>movePage('/ark-7600')}>AR/K-7600 Auto Refractokeratometer - Precise, Reliable, and Efficient</p> 
                        </div>
                      </div>

                      <div class='mbCats'>
                        <p class='text-white fs-5 fw-semibold mt-2'  >Auto Lensmeter <span><i class="bi bi-caret-down-fill"></i></span></p>
                        <div class='mbCatsOption'>
                          <p class='text-white mt-2'  onClick={()=>movePage('/gl-300')}>GL 300 Auto Lens meter</p>
                          <p class='text-white mt-2'  onClick={()=>movePage('/le-660a')}>LE-660A Auto Lens Edger: Precise, Efficient, and Versatile</p>
                          <p class='text-white mt-2'  onClick={()=>movePage('/tl-900')}>Visiotech Auto Lensmeter TL900 - Accurate, Easy-to-Use, and Versatile</p>
                        </div>
                      </div>

                      <div class='mbCats'>
                        <p class='text-white fs-5 fw-semibold mt-2'  onClick={()=>movePage('/small-machines')}>Small Machines</p> 
                      </div>
                      <div class='mbCats'>
                        <p class='text-white fs-5 fw-semibold mt-2' >Other Machines <span><i class="bi bi-caret-down-fill"></i></span></p>
                        <div class='mbCatsOption'>
                          <p class='text-white mt-t'  onClick={()=>movePage('/refraction')}>Optician's Refraction Chair Unit - Compact and Versatile</p>
                        </div>
                      </div>
                    </div>
                  </div>
                    

                  <p class='fs-5 fw-bold text-white' style={{cursor:'pointer'}} onClick={()=>movePage('/contact')}>Contact Us</p>
                  <div class='border text-black ' style={{width:'fit-content',borderRadius:'3px',background:'#FFFFFF' }} onClick={()=>movePage('/request-quote')}> 
                      <Button name={'Request a Quote'}/>
                    </div>  
            </div>
        </div>
    </div>
  )
}

export default Navbar