import React, { useEffect, useState } from 'react'
import AOS from "aos";
import "aos/dist/aos.css";
import axios from 'axios';
import Swal from 'sweetalert2';

const RequetQuate = () => {

    const [contact, setContact] = useState({})

    useEffect(() => {
        AOS.init({ duration: 1500 });
      });

      
      const contactInfo = (el)=>{
        const {name, value} = el.target 
        setContact({...contact,[name]:value})
      }

      const sendContactInfo =()=>{
        if(contact?.name && contact?.name != "" &&
        contact?.phone && contact?.phone != "" &&
        contact?.email && contact?.email != "" && 
        contact?.product && contact?.product != "" &&  
        contact?.message && contact?.message != "" ){ 
                axios.post(`https://hi-labsolution.net/api/diamond-contact.php`,contact)
                .then((res)=>{ 
                    if(res.data.responseStatus == true){
                        Swal.fire({
                          position: "center",
                          icon: "success",
                          title: `${res.data.responseMessage}`,
                          showConfirmButton: false,
                          timer: 2000,
                        })
                        setContact({
                            "name" : '',
                            'phone':'',
                            'email': '', 
                            'product':'',
                            'message':'' 
                        })
                    }else{
                        Swal.fire({
                            position: "center",
                            icon: "error",
                            title: `${res.data.responseMessage}`,
                            showConfirmButton: false,
                            timer: 2000,
                          })
                    }
                }).catch((err)=>{
                    console.log(err);
                })
            
        }else{
            Swal.fire({
                position: "center",
                icon: "error",
                title: `All fields must be required!`,
                showConfirmButton: false,
                timer: 2000,
              })
        }
      }

  return (
    <div>
        <p class='display-3 fw-bold' data-aos="fade-up">FILL THE DETAILS</p>
        <br />
        <div class='col-md-8 col-11 m-auto p-3 py-5 rounded-5' data-aos="zoom-in" style={{background:'#F1F1F9'}}>
            <div class='col-11 m-auto'>
            <div class='row ' >
                            <div class="col-md-6 mt-3">
                                <input type="text" class='form-control rounded-4 p-2 px-3' placeholder='Full Name' name='name' value={contact?.name} onChange={contactInfo} /> 
                            </div>
                            <div class="col-md-6 mt-3">
                                <input type="text" class='form-control rounded-4 p-2 px-3'placeholder='Select Product' name='product' value={contact?.product} onChange={contactInfo} /> 
                            </div> 
                        </div>
                        <div class='row '>
                            <div class="col-md-6 mt-3">
                                <input type="text" class='form-control rounded-4 p-2 px-3' placeholder='Email' name='email' value={contact?.email} onChange={contactInfo} /> 
                            </div>
                            <div class="col-md-6 mt-3">
                                <input type="text" class='form-control rounded-4 p-2 px-3' placeholder='Phone Number' name='phone' value={contact?.phone} onChange={contactInfo} /> 
                            </div> 
                        </div>
                        <div class=''> 
                            <textarea class='form-control mt-3 rounded-4 p-2 px-3' rows="3" placeholder='Your Message' name='message' value={contact?.message} onChange={contactInfo} ></textarea>
                      
                        </div>
                        <div class='d-flex justify-content-end mt-3'>
                            <button class='p-2 px-3 rounded-1 fw-semibold btn text-white' onClick={sendContactInfo}   style={{background:'#27448F'}}>Send Message</button>
                        </div>
            </div>

        </div>
        <br />
        <br />
    </div>
  )
}

export default RequetQuate