import React, { useEffect } from 'react'
import { Customer } from './MultiCarousel'
import Button from './Button';
import { useNavigate } from 'react-router-dom';
import AOS from "aos";
import "aos/dist/aos.css";

const customer = [
    {
        img : ` `,
        name :'Kyle Roberts DVM',
        post :'Customer Web Consultant',
        text :"Website design did exactly what you said it does. Just what I was looking for. Nice work on your website design.",
        star : 4
    },
    {
        img : ` `,
        name :'Sophia Anderson',
        post :'Internal Implementation Officer',
        text :"I will let my mum know about this, she could really make use of software! Very easy to use. Since I invested in software I made over 100,000 dollars profits. I just can't get enough of software. I want to get a T-Shirt with software on it so I can show it off to everyone.",
        star : 5
    },
    {
        img : ` `,
        name :'Stephen Brekke',
        post :'Legacy Integration Producer',
        text :"If you want real marketing that works and effective implementation - mobile app's got you covered.",
        star : 4
    }
]

const StarIcon = ({ count }) => {
    // Replace this with your star icon component, e.g., <span>*</span> or an SVG icon
    return (
      <div>
        {Array.from({ length: count }, (_, index) => (
          <span key={index}><i class="bi bi-star-fill text-primary"></i></span>
        ))}
      </div>
    );
  };  
const customerStotry = () =>{
    const arr = []
     for(let i = 0 ; i < customer.length ; i++){
        arr.push(
            <div style={{position:'relative'}}> 
                <div class='bg-white rounded-4'>
                    <div class='col-11 m-auto'> 
                        <div class='d-flex justify-content-end gap-3 pt-3'>
                            <StarIcon count={customer[i].star}/> 
                        </div>
                        <div class='my-3'>
                            <p class='tJustify'>{customer[i].text}</p>
                        </div>
                        <div class='d-flex justify-content-between  align-items-center pb-3  gap-1'>
                            <div class='text-start'>
                                <p class='fw-bold'>{customer[i].name}</p>
                                <p class='text-secondary' style={{fontSize:'14px'}}>{customer[i].post}</p> 
                            </div>
                            <div class='d-flex gap-2 align-items-center'>
                                <div class='p-1 bg-primary d-flex align-items-center justify-content-center ' style={{width:'25px',height:'25px',borderRadius:'50%'}}>
                                    <p  style={{fontSize:'14px'}}><i class="bi bi-hand-thumbs-up-fill text-white  "></i></p>
                                </div>
                                <p class='fw-bold'>Testimonial</p>
                            </div>
                        </div>
                     
                    </div> 
                </div>
                {/* <div style={{position:'absolute',width:'70px',height:'70px',borderRadius:'50%',top:'-35px',left:'35px'}}>
                    <img src={customer[i].img} style={{width:'100%',height:'100%',objectFit:'contain'}} alt="" />
                </div> */}
            </div>
        )
     }
     return arr;
}

const MiniProducts = () => {
    useEffect(() => {
        AOS.init({ duration: 1500 });
      });

    const navigate = useNavigate()
  return (
    <div>
        <div class='px-3'>
            <div class='text-start'> 
            <p class='titleText fw-bold' style={{color:'#27448F'}} data-aos="fade-up">Small</p> 
                <p class='titleText fw-bold' data-aos="fade-up"> <span>Machine</span> </p>
            </div> 

                <div class='col-11 m-auto mt-4 row rounded-5 productBox flex-column-reverse flex-md-row text-white ' style={{background:'#2A4CA4'}}>
                    <div class='col-md-6  d-flex   my-4'  >
                        <div class='col-11 m-auto text-start'>
                            <p class='fs-2 fw-bold  '>PD Meter</p>
                            <p class='  my-3'>A precision optical instrument with a Digital LCD Display for optometry purposes, used to measure the distance between a person's pupils for eyeglasses fitting.</p>
                            {/* <a href="" class=' ' onClick={()=>navigate('/gl-300')} >Read More</a> */}

                            <p class=' fw-bold mt-3'>Adjustable Viewing Distance Range</p>
                            <p class=''>Viewing distance can be adjusted from 30cm to infinity, accommodating various measurement needs.</p>

                            <p class=' fw-bold mt-3'>Effective Measurement Range</p>
                            <p class=''>Binocular measurement range : 50 to 80 mm</p>
                            <p  class=''>Monocular measurement range : 25 to 40 mm</p>
                            <p class=''>Resolution : 0.5 mm</p>

                            <p class=' fw-bold mt-3'>Monocular Measurement Available</p>
                            <p class=''>Enables separate measurement of the left and right eyes.</p>
                            <p  class=''>Displays measurements for each eye individually.</p>
                        

                            <p class=' fw-bold mt-3'>Power Saving Feature</p>
                            <p class=''>Automatic shutdown activates approximately 1 minute after completing an operation to conserve power and prevent unnecessary consumption.</p>
                             
                            

                        </div>
                    </div> 
                    <div class='col-md-6  imgIffect d-flex align-items-center justify-content-center  '>
                        <div class='col-11 m-auto my-5 ' style={{height:'fit-content'}}> 
                            <img src={require('../image/pdMeter.png')} style={{width:'200px',margin:'auto auto'}} alt="" data-aos="zoom-out" />
                        </div>
                    </div>
                </div>

                <div class='col-11 m-auto mt-4 row rounded-5 productBox flex-column-reverse flex-md-row ' style={{background:'#EDF0FA'}}>
                    <div class='col-md-6  d-flex   my-4'  >
                        <div class='col-11 m-auto text-start'>
                            <p class='fs-2 fw-bold '>Manual Edgers</p>
                            
                            {/* <a href="" class=' ' onClick={()=>navigate('/gl-300')} >Read More</a> */}
                             <ul class='mt-2'> 
                                <li>The durable and compact design of DiamondLab&#39;s hand edgers guarantees extended product life, even in challenging working environments.</li>
                                <li>DiamondLab&#39;s Diamond wheels are specifically engineered to provide excellent Supra grooving from their front face.</li>
                                <li>All DiamondLab edgers are equipped with a buffing wheel to achieve a glossy polish for CR lenses.</li>
                                <li>Achieve a glossy polish for CR lenses easily with the built-in buffing wheel.</li>
                                <li>The machine also includes a diamond drill attachment for superior drilling performance when working with 3-piece lenses.</li>
                             </ul>

                        </div>
                    </div> 
                    <div class='col-md-6  imgIffect d-flex align-items-center justify-content-center  '>
                        <div class='col-11 m-auto my-5' style={{height:'fit-content'}}> 
                            <img src={require('../image/manualEdger.webp')} style={{width:'300px',margin:'auto auto'}} alt="" data-aos="zoom-out" />
                        </div>
                    </div>
                </div>

                <div class='col-11 m-auto mt-4 row rounded-5 productBox flex-column-reverse flex-md-row text-white ' style={{background:'#5383FF'}}>
                    <div class='col-md-6  my-4'  >
                        <div class='col-11 m-auto text-start'>
                            <p class='fs-2 fw-bold '>CR Cutter</p>
                            {/* <a href="" class=' ' onClick={()=>navigate('/gl-300')} >Read More</a> */}
                            <ul class='mt-2'> 
                                <li>The robust design of the machine ensures an extended product lifespan even in demanding working conditions. </li>
                                <li>DiamondLab&#39;s glass cutter boasts a straightforward design, making it incredibly easy to operate. </li>
                                <li>The CR cutter cover guard provided by DiamondLab serves to safeguard the operator against accidents, thereby enhancing safety. </li>
                            </ul>
                        </div>
                    </div> 
                    <div class='col-md-6  imgIffect d-flex align-items-center justify-content-center  '>
                        <div class='col-11 m-auto my-5' style={{height:'fit-content'}}> 
                            <img src={require('../image/crCutter.webp')} style={{width:'270px',margin:'auto auto'}} alt="" data-aos="zoom-out" />
                        </div>
                    </div>
                </div>

                <div class='col-11 m-auto mt-4 row rounded-5 productBox flex-column-reverse flex-md-row text-white ' style={{background:'#000000'}}>
                    <div class='col-md-6    my-4'  >
                        <div class='col-11 m-auto text-start'>
                            <p class='fs-2 fw-bold '>Supra Grover</p>
                            {/* <a href="" class=' ' onClick={()=>navigate('/gl-300')} >Read More</a> */}
                            <ul class='mt-2'> 
                                <li>Considering the challenging working environments these machines operate in, DiamondLab's lens groover is intentionally crafted from materials that resist corrosion effectively. </li>
                                <li>Its smart design ensures precise grooving results throughout the entire circumference of the lens. </li>
                                <li>The body is non-corrosive, adding to its durability. </li>
                                <li>It is compatible with both plastic and glass lenses. </li>
                                <li>You can adjust the groove position, whether it&#39;s at the front, center, or rear, to suit your needs. </li>
                                <li>Accuracy is maintained on all edges, guaranteeing consistent results. </li>
                            </ul>
                        </div>
                    </div> 
                    <div class='col-md-6  imgIffect d-flex align-items-center justify-content-center  '>
                        <div class='col-11 m-auto my-5 ' style={{height:'fit-content'}}> 
                            <img src={require('../image/supraGroover.webp')} style={{width:'250px',margin:'auto auto'}} alt="" data-aos="zoom-out" />
                        </div>
                    </div>
                </div>

                <div class='col-11 m-auto mt-4 row rounded-5 productBox flex-column-reverse flex-md-row  text-white' style={{background:'#011C64'}}>
                    <br /><br />
                    <div class='col-md-6  my-4'  >
                        <div class='col-11 m-auto text-start '>
                            <p class='fs-2 fw-bold '>Drilling Machine</p>
                            {/* <a href="" class=' ' onClick={()=>navigate('/gl-300')} >Read More</a> */}
                            <ul class='mt-2'> 
                                <li>DiamondLab’s drilling machine ensures precise die sinking without deformation. </li>
                                <li>Installation is made easier with our product. </li>
                                <li>Enjoy a long service life with DiamondLab's solution. </li>
                                <li>Our product adopts an imported mill, making it compatible with any pattern plate. You can use any lens or gasket as the pattern plate form. </li>
                            </ul>
                        </div>
                    </div> 
                    <div class='col-md-5  imgIffect d-flex align-items-center justify-content-center  '>
                        <div class='col-11 m-auto my-5' style={{height:'fit-content'}}> 
                            <img src={require('../image/lensDrillingMachine.png')} style={{width:'250px',margin:'auto auto'}} alt="" data-aos="zoom-out" />
                        </div>
                    </div>
                    <br /><br />
                </div>

                <div class='col-11 m-auto mt-4 row rounded-5 productBox flex-column-reverse flex-md-row  bg-info' style={{background:''}}>
                    <div class='col-md-6  my-4'  >
                        <div class='col-11 m-auto text-start'>
                            <p class='fs-2 fw-bold '>Frame Heater</p>
                            {/* <a href="" class=' ' onClick={()=>navigate('/gl-300')} >Read More</a> */}

                            <ul class='mt-2'> 
                                <li>DiamondLab’s Frame Heater features a heavy-duty metal body, providing robustness and an extended lifespan. </li>
                                <li>It ensures uniform heating of the frame. </li>
                                <li>No maintenance is needed for DiamondLab’s frame heater. </li>
                                <li>Enjoy a strong and consistent air output. </li>
                                <li>Our product is designed with safety and user-friendliness in mind. </li>
                            </ul>
                        </div>
                    </div> 
                    <div class='col-md-6  imgIffect d-flex align-items-center justify-content-center  '>
                        <div class='col-11 m-auto my-5' style={{height:'fit-content'}}> 
                            <img src={require('../image/frameWarmer.png')} style={{width:'250px',margin:'auto auto'}} alt="" data-aos="zoom-out" />
                        </div>
                    </div>
                </div>

                <div class='col-11 m-auto mt-4 row rounded-5 productBox flex-column-reverse flex-md-row ' style={{background:'#D6C426'}}>
                    <div class='col-md-6  my-4'  >
                        <div class='col-11 m-auto text-start'>
                            <p class='fs-2 fw-bold  '>Trial Set</p>
                            <p class=' my-3'>Introducing an instrument designed to accurately assess a person's vision impairment:</p>
                            {/* <a href="" class=' ' onClick={()=>navigate('/gl-300')} >Read More</a> */}

                             
                            <p class=''><b>Instrument Dimensions :</b> Compact and convenient, measuring 42cm x 53cm x 10cm.</p>
                            <p class=''><b>Spheres :</b> Comes equipped with 25 pairs of both concave and convex lenses, spanning from 0.25D to 4.00D in precise 0.25D increments, and from 5.00D to 12.00D in convenient 1.00D intervals.</p>
                            <p class=''><b>Cylinder :</b> Features 12 pairs of concave lenses, offering a range from 0.25D to 2.00D in 0.25D steps, and from 2.50D to 4.00D in 0.50D increments.</p>
                            <p class=''><b>Prism :</b> Includes 4 prism pieces with values of 0.5, 1, 2, and 3.</p>
                            <p class=''><b>Accessories :</b> Includes 5 essential accessories, namely RF, BL, CL, PH, and SS.</p>
                            <p class=''><b>Case :</b> Presented in an elegant wooden case available in Red/Black and Silver/Golden finishes.</p>
                            <p class=''><b>Type K Lens :</b> Equipped with both metal and plastic rims, featuring a 25mm aperture.</p>
                            <p class=''>Available in both regular and small apertures, ensuring flexibility for various examination needs.</p>

                            
                        </div>
                    </div> 
                    <div class='col-md-6  imgIffect d-flex align-items-center justify-content-center  '>
                        <div class='col-11 m-auto my-5 ' style={{height:'fit-content'}}> 
                            <img src={require('../image/trialSet.webp')} style={{width:'250px',margin:'auto auto'}} alt="" data-aos="zoom-out" />
                        </div>
                    </div>
                </div>


          
             
        </div>
        <br />
        {/* <div  class='mt-5' style={{background:'#EDF0FA'}}>
            <div class='col-11 m-auto py-5'> 
                <p class='display-4 fw-bold' data-aos="fade-up">CUTOMER</p>
                <p class='display-4 fw-bold' style={{color:'#27448F'}} data-aos="fade-up">SUCCESS STORIES</p>
                <p class='col-md-5 col-11 m-auto fs-5' data-aos="fade-up">Hear from our satisfied B2B clients and learn how our optical machines have transformed their businesses.</p>
            </div> 
        </div> */}
        {/* <div style={{background:'linear-gradient(#EDF0FA 40%,#2A4BA3 0)'}}>
            <div class='col-11 m-auto'>

                 <Customer data={customerStotry()}/> 
            </div>
        </div> */}
        <div style={{background:'#2A4BA3',marginTop:'-2px'}}> 
        <br /><br />
            <div class='row col-11 m-auto py-4 gap-lg-0 gap-4 text-lg-center text-start  ' > 
                <div class='col-lg-8 col-12 m-auto text-start'>
                    <p class='display-4 text-white fw-bold ' data-aos="fade-up">WANT TO KNOW MORE ABOUT OUR PRODUCTS PLEASE CONNECT.</p>
                </div>
                <div class='col-lg-4 col-12 m-auto '>
                    <div class=' text-primary' style={{width:'fit-content',borderRadius:'3px',background:'#ffffff' }} onClick={()=>navigate('/request-quote')} > 
                        <Button class='p-2' name={'Get a Quote'}/>
                    </div>
                </div> 
            </div>
            <br /><br /><br />
        </div>
    </div>
  )
}

export default MiniProducts